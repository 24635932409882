<script>
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../app.config.json";
  import RFQForm from './RFQForm'

  export default {
    page: {
      title: "Request for Quotes",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Request for Quotes",
        items: [{
            text: "Update",
            href: "/",
          },
          {
            text: "Request for Quotes",
            active: true,
          },
        ],
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          enableTime: true,
        },
      };
    },
    components: {
      Layout,
      PageHeader,
      RFQForm
    },
    methods: {

    
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <RFQForm formType="update"/>
  </Layout>
</template>
